import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import MatxLayout from 'app/components/MatxLayout/MatxLayout';

const Analytics = Loadable(lazy(() => import('./Search')));


const dashboardRoutes = [
  { path: '/live', element: <Analytics />},
];

export default dashboardRoutes;
