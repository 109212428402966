import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./Item')));
const ChangePassword = Loadable(lazy(() => import('./ChangePassword')));
const Mobile = Loadable(lazy(() => import('./Mobile')));
const Listing = Loadable(lazy(() => import('./Listing')));
const Leads = Loadable(lazy(() => import('./Leads')));
const TGQuery = Loadable(lazy(() => import('./TelegramQuery')));
const ThirdParty = Loadable(lazy(() => import('./ThirdParty')));

const DNCReg = Loadable(lazy(() => import('./DNCReg')));

const dashboardRoutes = [
  { path: '/dashboard/default', element: <Analytics />, auth: authRoles.admin },
  { path: '/dashboard/mobile', element: <Mobile itemKey="numbers" />, auth: authRoles.admin },
  { path: '/dashboard/mymobile', element: <Listing itemKey="mynumbers" />, auth: authRoles.admin },
  { path: '/dashboard/changePassword', element: <ChangePassword />, auth: authRoles.admin },
  { path: '/dashboard/leads', element: <Leads itemKey="leads" />, auth: authRoles.admin },
  { path: '/dashboard/telegramQuery', element: <TGQuery itemKey="telegramUserQuery" />, auth: authRoles.admin },
  { path: '/dashboard/tpsg', element: <ThirdParty itemKey="sg3rdpartynumbers" />, auth: authRoles.admin },
  { path: '/dashboard/tpmy', element: <ThirdParty itemKey="my3rdpartynumbers" />, auth: authRoles.admin },
  { path: '/dashboard/dnc', element: <ThirdParty itemKey="dnc_file" />, auth: authRoles.admin },
  { path: '/dashboard/dncRegistry', element: <DNCReg itemKey="dnc_registry.json" />, auth: authRoles.admin },
];

export default dashboardRoutes;
