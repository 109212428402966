import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideModal } from 'app/redux/actions/ApplicationActions';


export default function AlertDialog() {

  const dispatch = useDispatch()
  const [open, setOpen] = useState(true);
  const { show, content, title, actionPanel } = useSelector(state => state?.application?.modal)
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => dispatch(hideModal())

  return (
    <Box>
      <Dialog
        open={!!content}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

        <DialogContent>

          <Box minHeight={'200px'} minWidth="300px">
            {
              content
            }
          </Box>


        </DialogContent>

        <DialogActions>
          {
            actionPanel
          }
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
