import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import SnackBar from 'app/components/CustomizedSnackbar'

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));

const Wrapper = ({ children }) => <>

  {children}
  <SnackBar />
</> 

const sessionRoutes = [
  { path: '/session/signup', element: <Wrapper><JwtRegister /></Wrapper> },
  { path: '/session/signin', element: <Wrapper><JwtLogin /></Wrapper> },
  { path: '/session/forgot-password', element: <Wrapper><ForgotPassword /></Wrapper> },
  { path: '/session/404', element: <Wrapper><NotFound /></Wrapper> },
];

export default sessionRoutes;
