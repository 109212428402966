export const navigations = [
  { name: 'Home', path: '/public/home', icon: 'camera', role: ['public'] },
  //{ name: '3rd Party SG Num', path: '/tpnum/sg', icon: 'filter_3', role: ['public'] },
  //{ name: '3rd Party MY Num', path: '/tpnum/my', icon: 'filter_3', role: ['public'] },
  { name: 'Terms of Service', path: '/tos', icon: 'camera', role: ['public'] },
  { name: 'Privacy Policy', path: '/privacy', icon: 'camera', role: ['public'] },

  { name: 'Reseller Tool', path: '/reseller/tool', icon: 'gavel', role: ['reseller'] },

  {
    name: 'Third Pary Number List', icon: 'dashboard', children: [
      { name: 'SG Feng Shui / Golden Number', path: '/sgnumber', iconText: 'A', role: ['public'] },
      { name: 'MY Feng Shui / Golden Number', path: '/mynumber', iconText: 'A', role: ['public'] },
    ], role: ['public']
  },
  { name: 'Reseller Search', path: '/reseller/home', icon: 'camera', role: ['reseller'] },
  { name: 'MY Reseller Search', path: '/myreseller/home', icon: 'camera', role: ['reseller'] },
  {
    name: 'Dashboard', icon: 'dashboard', children: [
      { name: 'Item', path: '/dashboard/default', iconText: 'A' },
      { name: 'Mobile Number', path: '/dashboard/mobile', iconText: 'B' },
      { name: 'MY Mobile Number', path: '/dashboard/mymobile', iconText: 'C' },
      { name: 'Leads', path: '/dashboard/leads', iconText: 'C' },
      { name: 'Telegram Query', path: '/dashboard/telegramQuery', iconText: 'D' },
      { name: 'Third Party SG', path: '/dashboard/tpsg', iconText: 'C' },
      { name: 'Third Party MY', path: '/dashboard/tpmy', iconText: 'D' },
      { name: 'DNC', path: '/dashboard/dnc', iconText: 'D' },
      { name: 'DNC Registry', path: '/dashboard/dncRegistry', iconText: 'D' },

    ],
  },
  {
    name: 'Media', icon: 'dashboard', children: [
      { name: 'Extract', path: '/dashboard/media/extract', iconText: 'A' },
    ],
  },
  {
    name: 'Components',
    icon: 'favorite',
    badge: { value: '30+', color: 'secondary' },
    children: [

      { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
      { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
      { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
      { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
      { name: 'Form', path: '/material/form', iconText: 'F' },
      { name: 'Icons', path: '/material/icons', iconText: 'I' },
      { name: 'Menu', path: '/material/menu', iconText: 'M' },
      { name: 'Progress', path: '/material/progress', iconText: 'P' },
      { name: 'Radio', path: '/material/radio', iconText: 'R' },
      { name: 'Switch', path: '/material/switch', iconText: 'S' },
      { name: 'Slider', path: '/material/slider', iconText: 'S' },
      { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
      { name: 'Table', path: '/material/table', iconText: 'T' },
    ],
  },
  /*
  {
    name: 'Session/Auth',
    icon: 'security',
    children: [
      { name: 'Sign in', iconText: 'SI', path: '/session/signin' },
      { name: 'Sign up', iconText: 'SU', path: '/session/signup' },
      { name: 'Forgot Password', iconText: 'FP', path: '/session/forgot-password' },
      { name: 'Error', iconText: '404', path: '/session/404' },
    ],
  },
  
  { name: 'Search', path: '/sale', icon: 'search' },
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  { label: 'PAGES', type: 'label' },

  { label: 'Components', type: 'label' },

  {
    name: 'Charts',
    icon: 'trending_up',
    children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  },
  {
    name: 'Documentation',
    icon: 'launch',
    type: 'extLink',
    path: 'http://demos.ui-lib.com/matx-react-doc/',
  },
  */
];
