import {
  SET_MESSAGE,
  SET_DATA,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_LOADER,
  HIDE_LOADER
} from '../actions/ApplicationActions';

const initialState = {
  message: {
    text: ""
  },
  drawer: {

  },
  data: {

  },
  modal: {
    show: false,
    content: null
  },
  loader: {
    show: false
  }
};

const EcommerceReducer = function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE: {

      return {
        ...state,
        message: {
          text: action?.payload.text
        },
      };
    }
    case SET_DATA: {
      const { ns, data } = action?.payload
      console.log(action)
      return {
        ...state,
        data: {
          ...state.data,
          [ns]: data
        }
      };
    }

    case SHOW_MODAL: {
      const { content, show } = action?.payload
      console.log(action)
      return {
        ...state,
        modal: {

          ...action?.payload
        }
      }
    }

    case HIDE_MODAL: {
      const { content, show } = action?.payload
      console.log(action)
      return {
        ...state,
        modal: {

          content: null,
          show: false
        }
      }
    }

    case HIDE_LOADER: {

      return {
        ...state,
        loader: {
          show: false
        }
      }
    }

    case SHOW_LOADER: {
      return {
        ...state,
        loader: {
          show: true
        }
      }
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default EcommerceReducer;
