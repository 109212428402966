import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Analytics = Loadable(lazy(() => import('./Item')));

const dashboardRoutes = [
  { path: '/dashboard/media/extract', element: <Analytics />, auth: authRoles.admin },

];

export default dashboardRoutes;
