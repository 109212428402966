export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_DATA = 'SET_DATA';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';


export const setApplicationMessage = (payload) => {
  return {
    type: SET_MESSAGE,
    payload
  }
}

export const setData = (ns, data) => {
  return {
    type: SET_DATA,
    payload: {
      ns,
      data
    }

  }
}

export const showModal = (data) => {
  return {
    type: SHOW_MODAL,
    payload: {
      show: true,
      ...data

    }
  }
}

export const hideModal = (data) => {
  return {
    type: HIDE_MODAL,
    payload: {

    }

  }
}

export const showLoader = () => {
  return {
    type: SHOW_LOADER,
  }
}

export const hideLoader = () => {
  return {
    type: HIDE_LOADER,
  }
}



