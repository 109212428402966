import { Alert, Button, Snackbar } from "@mui/material";
import { amber, green } from "@mui/material/colors";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setApplicationMessage } from 'app/redux/actions/ApplicationActions'

const ContentRoot = styled("div")(({ theme }) => ({
  "& .icon": { fontSize: 20 },
  "& .success": { backgroundColor: green[600] },
  "& .warning": { backgroundColor: amber[700] },
  "& .error": { backgroundColor: theme.palette.error.main },
  "& .info": { backgroundColor: theme.palette.primary.main },
  "& .iconVariant": { opacity: 0.9, marginRight: theme.spacing(1) },
  "& .message": { display: "flex", alignItems: "center" },
  "& .margin": { margin: theme.spacing(1) },
}));

export default function CustomizedSnackbars() {

  const dispatch = useDispatch();
  const message = useSelector(state => state?.application?.message)

  useEffect(() => {

  }, [])
  function handleClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setApplicationMessage(""))
  }

  return (
    <ContentRoot>
      {
        message?.text && <Snackbar open={true} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }} variant="filled">
            {message?.text}
          </Alert>
        </Snackbar>
      }
    </ContentRoot>
  );
}
