import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import MatxLayout from 'app/components/MatxLayout/MatxLayout';

const Reseller = Loadable(lazy(() => import('./Reseller')));
const Home = Loadable(lazy(() => import('./Home')));
const MYReseller = Loadable(lazy(() => import('./MYReseller')));
const ResellerTool = Loadable(lazy(() => import('./ResellerTool')));

const ThirdParty = Loadable(lazy(() => import('./ThirdParty')));
const TOS = Loadable(lazy(() => import('./TOS')));
const Privacy = Loadable(lazy(() => import('./Privacy')));
const ITEM_KEY = 'my3rdpartynumbers'
const dashboardRoutes = [
  { path: '/public/home', element: <Home /> },
  { path: '/reseller/home', element: <Reseller /> },
  { path: '/myreseller/home', element: <MYReseller /> },
  { path: '/reseller/tool', element: <ResellerTool /> },
  { path: '/tos', element: <TOS /> },
  { path: '/privacy', element: <Privacy /> },

  { path: '/sgnumber', element: <ThirdParty ITEM_KEY="sg3rdpartynumbers" title={"Singapore Feng Shui/Golden Number"} /> },
  { path: '/mynumber', element: <ThirdParty ITEM_KEY="my3rdpartynumbers" title={"Malaysia Feng Shui/Golden Number"} /> },
];

export default dashboardRoutes;
