import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import mediaRoutes from 'app/views/media/DashboardRoutes';
import liveRoutes from 'app/views/live/LiveRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from 'app/components/MatxLayout/MatxLayout';
import publicLiveRoutes from 'app/views/public/LiveRoutes';
const routes = [


  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [...mediaRoutes, ...dashboardRoutes, ...chartsRoute, ...liveRoutes, ...materialRoutes],
  },

  {
    element: (
      <MatxLayout />
    ),
    children: [...publicLiveRoutes],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="/public/home" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
