import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import Parse from 'app/vendor/Parse'
import { isError } from 'lodash'


const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const getUser = () => {
    return Parse.User.current();;

}

const getServerUser = async () => {
    const user = Parse.User.current();
    return await user?.fetch();
}

const isAuth = () => {
    return !!Parse.User.current()
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const user = Parse.User.current();
        if (user) {
            dispatch({
                type: 'LOGIN',
                payload: {
                    user,
                },
            })
        }
    }, [])
    const login = async (email, password) => {
        const user = await Parse.User.logIn(email, password)
        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (email, username, password, mobile) => {
        return await Parse.Cloud.run('createUser', {
            email,
            name: username,
            password,
            contact: mobile
        })
    }

    const logout = async () => {
        await Parse.User.logOut()

        dispatch({ type: 'LOGOUT' })
    }



    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                getUser,
                isAuth,
                getServerUser
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
